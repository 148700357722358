<template lang="pug">
form(ref="form" @submit.prevent="submitForm")
  transition(name="fade")
    .notification.is-warning(v-if="showBroke") {{ brokeMessage }}
  transition(name="fade")
    .notification.is-danger(v-if="showError") {{ errorMessage }}
  transition(name="fade")
    .notification.is-success(v-if="showSuccess") {{ successMessage }}
  slot
</template>

<script>
export default {
  props: {
    complete: Function
  },

  data () {
    return {
      defaultError: 'Please fix the highlighted fields',
      valid: true,
      brokeMessage: null,
      successMessage: null
    }
  },

  computed: {
    showBroke () {
      return this.brokeMessage && this.brokeMessage != ''
    },
    showSuccess () {
      return this.successMessage && this.successMessage != ''
    },
    showError () {
      return !this.valid
    },
    errorMessage () {
      return this.defaultError
    }
  },

  methods: {
    submitForm () {
      if (this.showBroke || this.showSuccess) return
      if (this.validateForm()) {
        this.disableForm()
        this.complete()
      }
    },
    formFields () {
      return this.$refs.form.querySelectorAll('input,textarea,select')
    },
    validateForm () {
      this.formFields().forEach(field => {
        const event = new Event('change')
        field.dispatchEvent(event)
      })
      this.valid = this.$refs.form.checkValidity()
      if (!this.valid) this.$refs.form.reportValidity()
      return this.valid
    },
    resetForm () {
      this.brokeMessage = null
      this.successMessage = null
      this.valid = true
      this.enableForm()
    },
    setDisabledForFields (value) {
      this.formFields().forEach(field => {
        if (value) field.setAttribute('disabled', '')
        else field.removeAttribute('disabled')
      })
    },
    enableForm () {
      this.setDisabledForFields(false)
    },
    disableForm () {
      this.setDisabledForFields(true)
    },
    broke (message) {
      this.brokeMessage = message
    },
    success (message) {
      this.successMessage = message
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: all 0.1s;
}
.fade-enter, .fade-leave-to {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  opacity: 0;
}
</style>
