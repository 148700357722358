<template lang="pug">
div
  nav.navbar.is-dark
    .navbar-brand
      router-link.navbar-item.logo-image(:to="{ path: '/' }")
        img(src="/logo.svg")
      router-link.navbar-item(:to="{ path: '/' }")
        b.is-italic.logo.is-size-4.is-size-5-mobile
          | MOTOR
          |
          span.green CAR
          |
          | EXPORT
      a.navbar-burger.burger(ref="burger" @click="toggleLinks")
        span
        span
        span
    .navbar-menu(ref="links")
      .navbar-start
        router-link.navbar-item(
          v-for="link in links"
          :to="{ path: link.path }"
          :key="link.text"
          :class="{'is-active': isActive(link)}") {{ link.text }}
      .navbar-end
        .navbar-item
          a(href="tel:248-446-8289") Phone 248.446.8289
        .navbar-item.has-dropdown.is-hoverable(v-if="isAuthenticated")
          a.navbar-link Account
          .navbar-dropdown.is-right
            .dropdown-item {{ user.name }}
            a.dropdown-item(v-if="isAdmin" @click="goToAdmin") Admin
            a.dropdown-item(@click="logout") Logout
        .navbar-item(v-if="!isAuthenticated")
          a.button.is-primary(:class="{'is-loading': authLoading}" @click="login")
            strong Login
  .mobile-navbar.is-hidden
    .columns.is-mobile.is-gapless
      .column
        router-link.mobile-router-link.has-text-white.has-text-centered(:to="{name: 'vehicles'}")
          p.mobile-router-icon
            font-awesome-icon.has-text-centered(icon="car" size="1x")
          p.nav-title.is-size-6 Vehicles
      .column
        router-link.mobile-router-link.has-text-white.has-text-centered(:to="{name: 'vehicles'}")
          p.mobile-router-icon
            font-awesome-icon.has-text-centered(icon="car" size="1x")
          p.nav-title.is-size-6 Vehicles
      .column
        router-link.mobile-router-link.has-text-white.has-text-centered(:to="{name: 'vehicles'}")
          p.mobile-router-icon
            font-awesome-icon.has-text-centered(icon="car" size="1x")
          p.nav-title.is-size-6 Vehicles
</template>

<script>
export default {
  data () {
    return {
      showLinks: false
    }
  },
  computed: {
    authLoading () {
      return this.$auth.loading
    },
    isAuthenticated () {
      return this.$auth.isAuthenticated
    },
    isAdmin () {
      return this.$auth.isAdmin
    },
    user () {
      return this.$auth.user
    }
  },
  props: {
    links: Array
  },
  methods: {
    login () {
      this.$auth.loginWithRedirect()
    },
    logout () {
      this.$auth.logout()
    },
    goToAdmin () {
      this.$router.push({name: 'manage-vehicles'})
    },
    isActive (link) {
      return link.path === this.$route.path
    },
    toggleLinks () {
      this.showLinks = !this.showLinks
      if (this.showLinks) {
        this.$refs.links.classList.add('is-active')
        this.$refs.burger.classList.add('is-active')
      } else {
        this.$refs.links.classList.remove('is-active')
        this.$refs.burger.classList.remove('is-active')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/_variables";
.is-active {
  background-color: $black-ter !important;
}
.navbar-item, .navbar-item a:not(.button) {
  color: white;
}
.mobile-navbar {
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $primary;
}
.mobile-router-link {
  width: 100%;
}
.mobile-router-icon {
  margin-top: 0.5rem;
}
.nav-title {
  margin-top: -0.3rem;
  margin-bottom: 0.5rem;
}
.router-link-active p {
  color: $yellow;
}
.logo {
  font-family: Raleway;
  font-weight: 900;
  font-style: italic;
}
@media screen and (max-width: 768px) {
  .logo-image {
    width: 100px;
    padding: 0.75rem 0.5rem 0.25rem 0.5rem !important;
  }
}
.green {
  color: $primary;
}
.dropdown-item {
  color: white;
}
a.dropdown-item:hover {
  background-color: $black-bis;
}
.navbar-dropdown {
  border-top: 0;
}
</style>
