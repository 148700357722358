<template lang="pug">
div
  section.hero.is-primary
    .hero-body
      .container
        p.title.is-2 Quality Autos at Exceptional Prices
        p.subtitle.is-4 {{ page.name }}
  section.section
    .container
      .content(v-html="page.body")
</template>

<script>
import Page from '@/graphql/Page.gql'

export default {
  components: {
  },

  mounted () {
    window.scrollTo(0, 0)
  },

  data () {
    return {
      page: {}
    }
  },

  computed: {
  },

  apollo: {
    page: {
      query: Page,
      variables () {
        return {
          slug: this.$route.params.slug
        }
      },
      update: data => data.pages[0]
    }
  },

  filters: {
  },

  methods: {
  }
}
</script>

<style scoped lang="scss">
.section {
  margin: 0.5rem 0;
}
</style>
