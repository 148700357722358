<template lang="pug">
div
  section.hero.is-primary
    .hero-body
      .container
        p.title.is-2 Admin
        p.subtitle.is-4 Manage Vehicles
    .container
      form.search-form(@submit.prevent)
        .field
          p.control.has-icons-left
            input.input(
              autofocus
              placeholder="Year make model"
              type="search"
              v-model="search")
            span.icon.is-small.is-left
              font-awesome-icon(icon="search" size="1x")

      transition-group(name="list" tag="div")
        list-row.list-row(v-for="vehicle in vehicles"
          :key="vehicle.id"
          :title="vehicle | title"
          :subtitle="vehicle.subtitle"
          :price="vehicle.price"
          :retailPrice="vehicle.retail_price"
          :miles="vehicle.miles"
          :item="vehicle"
          :photo="vehicle | featuredPhoto"
          v-on:action="viewVehicle")
</template>

<script>
import CurrentVehicles from '@/graphql/CurrentVehicles.gql'

export default {
  components: {
  },

  mounted () {
    window.scrollTo(0, 0)
  },

  data () {
    return {
      vehicles: [],
      search: ''
    }
  },

  computed: {
    yearMatch () {
      const matches = this.search.match(/(19|20)\d{2}/)
      return matches ? parseInt(matches[0]) : null
    },
    minYear () {
      return this.yearMatch || 0
    },
    maxYear () {
      return this.yearMatch || 2500
    },
    searchTerms () {
      return this.search
        .replace(this.yearMatch, '')
        .replace(/^\d+/, '')
        .trim()
        .replace(/\s+/, ' ')
        .split(' ')
    },
    makePattern () {
      return this.searchTerms[0].toLowerCase() + '%'
    },
    modelPattern () {
      const terms = this.searchTerms
      const modelName = terms.length > 1 ? terms[1].toLowerCase() : ''
      return modelName + '%'
    },
  },

  apollo: {
    vehicles: {
      query: CurrentVehicles,
      variables () {
        return {
          min_year: this.minYear,
          max_year: this.maxYear,
          make: this.makePattern,
          model: this.modelPattern,
          min_vehicle_type: 0,
          max_vehicle_type: 3,
          sort: [{id: 'asc'}]
        }
      }
    }
  },

  filters: {
  },

  methods: {
  }
}
</script>

<style scoped lang="scss">
.section {
  margin: 0.5rem 0;
}
</style>
