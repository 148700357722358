<template lang="pug">
div(ref="content")
  section.hero.is-primary
    .hero-body
      .container
        p.title.is-2.is-size-3-mobile Quality Autos at Exceptional Prices
        h1.subtitle.is-4.is-size-5-mobile Complete Vehicle List
  section.section
    .container
      form.search-form(@submit.prevent)
        .field
          p.control.has-icons-left
            input.input(
              autofocus
              placeholder="Year make model"
              type="search"
              v-model="search")
            span.icon.is-small.is-left
              font-awesome-icon(icon="search" size="1x")

      .level
        .level-left
          .level-item
            .buttons.has-addons
              .button.is-rounded(
                v-for="bodyType in bodyTypes"
                @click="setVehicleType(bodyType.id)"
                :class="{'is-info': vehicleType == bodyType.id}")
                | {{ bodyType.label }}
        .level-right
          .level-item
            .buttons.has-addons
              .button.is-rounded(
                v-for="option in sorting"
                @click="sort = option.type"
                :class="{'is-info': sort === option.type}")
                span.icon.is-small(v-if="sort === option.type")
                  font-awesome-icon(icon="sort-amount-down-alt")
                span {{ option.label }}

      transition-group(name="list" tag="div")
        list-row.list-row(v-for="vehicle in vehicles"
          :key="vehicle.id"
          :title="vehicle | title"
          :subtitle="vehicle.subtitle"
          :price="vehicle.price"
          :retailPrice="vehicle.retail_price"
          :miles="vehicle.miles"
          :item="vehicle"
          :photo="vehicle | featuredPhoto"
          v-on:action="viewVehicle")
</template>

<script>
import ListRow from '@/components/ListRow'
import CurrentVehicles from '@/graphql/CurrentVehicles.gql'

export default {
  components: {
    ListRow
  },

  mounted () {
    window.scrollTo(0, 0)
  },

  data () {
    return {
      vehicles: [],
      vehicleType: 0,
      bodyTypes: [
        { id: 0, label: 'All' },
        { id: 1, label: 'Car' },
        { id: 2, label: 'SUV' },
        { id: 3, label: 'Truck' }
      ],
      sorting: [
        { type: 'newest', label: 'Recently added' },
        { type: 'price', label: 'Price' }
      ],
      search: '',
      sort: 'newest'
    }
  },

  computed: {
    searching () {
      return this.search !== ''
    },
    yearMatch () {
      const matches = this.search.match(/(19|20)\d{2}/)
      return matches ? parseInt(matches[0]) : null
    },
    minYear () {
      return this.yearMatch || 0
    },
    maxYear () {
      return this.yearMatch || 2500
    },
    minVehicleType () {
      return this.vehicleType
    },
    maxVehicleType () {
      return this.vehicleType == 0 ? 3 : this.vehicleType
    },
    searchTerms () {
      return this.search
        .replace(this.yearMatch, '')
        .replace(/^\d+/, '')
        .trim()
        .replace(/\s+/, ' ')
        .split(' ')
    },
    makePattern () {
      return this.searchTerms[0].toLowerCase() + '%'
    },
    modelPattern () {
      const terms = this.searchTerms
      const modelName = terms.length > 1 ? terms[1].toLowerCase() : ''
      return modelName + '%'
    },
    sortBy () {
      if (this.sort === 'newest') {
        return [{featured_photo: 'desc_nulls_last'}, {has_price: 'desc'}, {id: 'desc'}]
      } else {
        return [{price: 'asc_nulls_last'}, {id: 'desc'}]
      }
    }
  },

  apollo: {
    vehicles: {
      query: CurrentVehicles,
      variables () {
        return {
          min_year: this.minYear,
          max_year: this.maxYear,
          make: this.makePattern,
          model: this.modelPattern,
          min_vehicle_type: this.minVehicleType,
          max_vehicle_type: this.maxVehicleType,
          sort: this.sortBy
        }
      }
    }
  },

  filters: {
    title (vehicle) {
      return [vehicle.year, vehicle.make, vehicle.model].join(' ')
    },
    featuredPhoto (vehicle) {
      const domain = 'https://cdn.motorcarexport.com'
      if(!vehicle.featured_photo) return domain + '/placeholder.jpg'
      if(process.env.NODE_ENV == 'development') {
        return domain + '/vehicles/845/photos/1108'
      } else {
        const photo = vehicle.photos[0]
        return domain + '/vehicles/' + vehicle.id + '/photos/' + photo.id
      }
    }
  },

  methods: {
    setVehicleType (id) {
      this.vehicleType = id
    },
    viewVehicle (vehicle) {
      this.$router.push({name: 'vehicle', params: {id: vehicle.id}})
    }
  }
}
</script>

<style scoped lang="scss">
.outer-content {
  padding: 2rem 1rem 0;
}
.search-form {
  margin-bottom: 1.5rem;
}
.list-row {
  transition: all .5s;
}
.list-leave-active {
  position: absolute;
}
.list-enter, .list-leave-to {
  opacity: 0;
}
</style>
