// Core
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Auth0Plugin } from './auth'
import apolloProvider from '@/apollo'
import FontAwesomeIcon from '@/icons'
import VueAnalytics from 'vue-analytics'
import './registerServiceWorker'
import Toasted from 'vue-toasted'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

// Auth0
Vue.use(Auth0Plugin, {
  apollo: apolloProvider,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

// Google anlytics
Vue.use(VueAnalytics, {
  id: 'UA-6337190-6',
  router
})

// Font awesome icons
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Toasts
Vue.use(Toasted, {
  position: 'top-center',
  // duration: 5000,
  iconPack: 'callback'
})

Vue.config.productionTip = false

// Base components
// Calling these W components, for WOV
const requireComponent = require.context(
  // The relative path of the components folder
  './components',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /W[A-Z]\w+\.(vue|js)$/
)
requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )
  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

new Vue({
  router,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
