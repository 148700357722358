import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faSortAmountDown,
  faSortAmountDownAlt,
  faPlus,
  faCaretDown,
  faExclamationCircle,
  faQuestionCircle,
  faCalendarAlt,
  faTrash,
  faSave,
  faSearch,
  faCar,
  faSignal
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faSortAmountDown,
  faSortAmountDownAlt,
  faPlus,
  faCaretDown,
  faExclamationCircle,
  faQuestionCircle,
  faCalendarAlt,
  faTrash,
  faSave,
  faSearch,
  faCar,
  faSignal
)

export default FontAwesomeIcon
