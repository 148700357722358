<template lang="pug">
div(:class="rowClasses" @click="performClick")
  .column.is-1.has-text-centered(v-if="checkable")
    font-awesome-icon(icon="check-circle" size="2x" :class="iconClass")
  .column.is-one-fifth-tablet(v-if="photo")
    div.image.is-4by6
      img(:src="photo")
  .column
    p.title.is-3.is-size-4-mobile.has-text-grey-dark {{ title }}
    .subtitle.is-5.is-size-6-mobile.level.is-mobile
      .level-left
        .level-item(v-if="icon")
          font-awesome-icon(:icon="icon" size="1x" :type="iconTypeOrDefault")
        .level-item
          span {{ subtitle }}
    p.is-hidden-mobile(v-if="miles") Miles: {{ miles | prettyMiles }}
  .column.has-text-right-tablet.pricing.is-one-quarter-tablet
    p.title.has-text-green-dark.is-3.is-size-5-mobile(v-if="showSold") Sold
    p.title.has-text-green-dark.is-3.is-size-5-mobile(v-if="showPrice") {{ price | prettyPrice }}
    p.subtitle.has-text-green-dark.is-3.is-size-5.strikethrough(v-if="showSoldPrice") {{ price | prettyPrice }}
    div.is-hidden-mobile(v-if="retailPrice")
      p.is-6
        | Retail price:
        br
        | {{ retailPrice | prettyPrice }}
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    price: {
      type: Number,
      required: false,
    },
    retailPrice: {
      type: Number,
      required: false,
    },
    miles: {
      type: Number,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    iconType: {
      type: String,
      required: false,
    },
    photo: {
      type: String,
      required: false,
    },
    item: {
      type: Object,
      required: false,
    },
    onClick: {
      type: Function,
      required: false,
    },
    checkable: {
      type: Boolean,
      required: false,
      default: false,
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    iconTypeOrDefault() {
      return this.iconType || "is-grey-lighter";
    },
    rowClasses() {
      return (
        "listRow columns is-vcentered is-gapless-mobile row" +
        (this.disabled ? " disabled" : "")
      );
    },
    iconClass() {
      if (this.checked) {
        return "has-text-success";
      } else if (this.disabled) {
        return "has-text-grey-lighter";
      } else {
        return "has-text-grey-light";
      }
    },
    showPrice() {
      return !this.showSold && this.price;
    },
    showSold() {
      return this.item.sold;
    },
    showSoldPrice() {
      return this.item.sold && this.price;
    },
  },
  filters: {
    prettyPrice(value) {
      if (!value) return "Call for price";
      const options = {
        style: "currency",
        currency: "USD",
        maximumSignificantDigits: 3,
      };
      return Intl.NumberFormat("en-US", options).format(value);
    },
    prettyMiles(value) {
      if (!value) return null;
      return Intl.NumberFormat("en-US").format(value);
    },
  },
  methods: {
    performClick() {
      if (this.disabled) {
        return;
      }
      if (this.checkable) {
        this.$emit("check", this.item);
      } else {
        this.$emit("action", this.item);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.listRow {
  .title {
    margin: 0.5rem 0 0.5rem;
  }
  .subtitle {
    margin: 0.5rem 0;
  }
  p {
    margin: 0.5rem 0;
  }
}
@media screen and (max-width: 768px) {
  .pricing {
    margin-bottom: 2rem !important;
  }
}
.row:hover {
  background: hsl(0, 0%, 96%);
  cursor: pointer;
}
.row.disabled {
  background-color: hsl(0, 0%, 98%);
  &:hover {
    background: none;
    cursor: not-allowed;
  }
  .title {
    color: hsl(0, 0%, 80%);
  }
}
</style>
