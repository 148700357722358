import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_URL
})

const getAccessToken = async () => {
  try {
    if (!Vue.prototype.$auth.isAuthenticated) return { headers: {} }
    Vue.prototype.$auth.getTokenSilently().then(token => {
      return { headers: { authorization: `Bearer ${token}` } }
    }).catch(error => {
      console.error(error)
    })
  } catch (err) {
    return { headers: {} }
  }
}

const middlewareLink = setContext(async () => {
  return getAccessToken()
})

const httpAuthLink = middlewareLink.concat(httpLink)

const client = new ApolloClient({
  link: httpAuthLink,
  cache: new InMemoryCache({
    addTypename: true,
    freezeResults: true
  }),
  assumeImmutableResults: true,
  connectToDevTools: process.env.NODE_ENV === 'development'
})

const provider = new VueApollo({
  defaultClient: client
})

Vue.use(VueApollo)

export default provider
