import Vue from 'vue'
import router from './router'
import createAuth0Client from '@auth0/auth0-spa-js'
import jwtDecode from 'jwt-decode'
import CURRENT_USER from '@/graphql/CurrentUser.gql'
import CREATE_USER from '@/graphql/CreateUser.gql'

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => {
  const url = window.localStorage.getItem('returnTo')
  window.localStorage.removeItem('returnTo')
  const path = url || 'vehicles'
  router.push({ path: path })
}
let instance

/** Returns the current instance of the SDK */
export const getInstance = () => instance

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...options
}) => {
  if (instance) return instance

  // The 'instance' is simply a Vue object
  instance = new Vue({
    apolloProvider: options.apollo,

    data () {
      return {
        loading: true,
        isAuthenticated: false,
        isAdmin: false,
        jwtUser: {},
        user: null,
        roles: [],
        accessToken: null,
        auth0Client: null,
        popupOpen: false,
        error: null
      }
    },

    computed: {
      authId () {
        if (this.jwtUser && this.jwtUser.sub) {
          return this.jwtUser.sub
        } else {
          return 'unknown'
        }
      },
      // This puts together data needed to insert a new user into the db
      userData () {
        return {
          auth_id: this.jwtUser.sub,
          nickname: this.jwtUser.nickname,
          name: this.jwtUser.name,
          email: this.jwtUser.email,
          picture: this.jwtUser.picture,
          last_login: new Date().toISOString().slice(0, 19).replace('T', ' '),
          roles: this.roles
        }
      }
    },
    apollo: {
      user: {
        query: CURRENT_USER,
        variables () {
          return { authId: this.authId }
        },
        update (data) {
          if (data.users) return data.users[0]
        }
      }
    },
    methods: {
      /** Update the user object with the results of one of the GraphQL queries */
      /** Authenticates the user using a popup window */
      async loginWithPopup (o) {
        this.popupOpen = true

        try {
          await this.auth0Client.loginWithPopup(o)
        } catch (e) {
          // eslint-disable-next-line
          console.error(e)
        } finally {
          this.popupOpen = false
        }

        this.jwtUser = await this.auth0Client.getUser()
        this.isAuthenticated = true
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback () {
        this.loading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          this.jwtUser = await this.auth0Client.getUser()
          this.isAuthenticated = true
        } catch (e) {
          this.error = e
        } finally {
          this.loading = false
        }
      },
      /** Get the current user **/
      getUser () {
        return this.auth0Client.getUser()
      },
      /** Remember where we were so we can return to there after login **/
      rememberReturnTo (path) {
        const url = path || window.location.pathname
        window.localStorage.setItem('returnTo', url)
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect (returnTo) {
        this.rememberReturnTo(returnTo)
        return this.auth0Client.loginWithRedirect()
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims (o) {
        return this.auth0Client.getIdTokenClaims(o)
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently (o) {
        return this.auth0Client.getTokenSilently(o)
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup (o) {
        return this.auth0Client.getTokenWithPopup(o)
      },
      /** Logs the user out and removes their session on the authorization server */
      logout (o) {
        return this.auth0Client.logout(o)
      },
      async loadRoles () {
        this.accessToken = await this.getTokenSilently()
        if (this.accessToken) {
          const decoded = jwtDecode(this.accessToken)
          this.roles = decoded && decoded['https://hasura.io/jwt/claims']['x-hasura-allowed-roles']
          this.isAdmin = this.roles && this.roles.includes('admin')
        }
      },
      async updateDb () {
        this.$apollo.mutate({
          mutation: CREATE_USER,
          variables: this.userData
        }).then((result) => {
          const newUser = result.data.insert_users.returning[0]
          if (newUser) this.updateUserData(newUser)
        }).catch((error) => {
          console.error(error)
        })
      }
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created () {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        domain: process.env.VUE_APP_AUTH0_DOMAIN,
        redirect_uri: `${window.location.origin}`,
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        audience: process.env.VUE_APP_AUTH0_AUDIENCE
      })

      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback()

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState)
        }
      } catch (e) {
        this.error = e
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.jwtUser = await this.auth0Client.getUser()
        if (this.isAuthenticated) {
          await this.loadRoles()
          await this.updateDb()
        }
        this.loading = false
      }
    }
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install (Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  }
}
