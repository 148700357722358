<template lang="pug">
div
  script(v-html="jsonLd" type="application/ld+json")

  // Hero title
  section.hero.is-primary
    .hero-body
      .container
        h1.title.is-2.is-size-3-mobile {{ vehicle | title }}
        h2.subtitle.is-4.is-size-5-mobile {{ vehicle.subtitle }}

  // Photos
  section.section
    .container
      hooper.main(v-if="photos" :settings="hooperMainSettings" group="group1" ref="slideshow")
        slide(v-for="(photo, index) in photos" :key="photo.id" :index="index")
          center
            img(:src="photo.url")
        navigation.is-hidden-touch(slot="hooper-addons")
      hooper.nav.is-hidden-touch(v-if="photos" :settings="hooperNavSettings" group="group1")
        slide(v-for="(photo, index) in photos" :key="photo.id" :index="index")
          img(:src="photo.url" @click="updateSlideShow(index)")

      // Call-to-action
      .columns.has-background-info

        // Ask a question
        .column.has-text-centered.call-to-action
          button.button.is-info.is-light(@click="askQuestion")
            span.icon.is-small
              font-awesome-icon(icon="question-circle")
            span Have a question?

        // Schedule an appointment
        .column.has-text-centered.call-to-action
          button.button.is-info.is-light(@click="scheduleAppt")
            span.icon.is-small
              font-awesome-icon(icon="calendar-alt")
            span Schedule an appointment

      // Pricing
      .columns.content-section(v-if="vehicle.price")
        .column.has-text-centered
          p.title.is-2.is-size-3-mobile.has-text-green-dark
            | Sale Price:
            br
            | {{ vehicle.price | prettyPrice }}
        .column.has-text-centered(v-if="vehicle.retail_price")
          p.title.is-2.is-size-4-mobile.has-text-grey.has-text-weight-normal
            | Retail Price:
            br
            | {{ vehicle.retail_price | prettyPrice }}
        .column.has-text-centered(v-if="vehicle.retail_price")
          p.title.is-2.is-size-4-mobile.has-text-primary
            | Savings:
            br
            | {{ savings | prettyPrice }}

      // Information
      .columns.is-variable.is-8-desktop
        .column
          h3.subtitle.is-3 Information
          table.table.is-fullwidth
            tbody
              tr
                th Year
                td {{ vehicle.year }}
              tr
                th Make
                td {{ vehicle.make }}
              tr
                th Model
                td {{ vehicle.model }}
              tr(v-if="vehicle.vin")
                th VIN
                td {{ vehicle.vin }}
              tr(v-if="vehicle.miles")
                th Miles
                td {{ vehicle.miles | prettyMiles }}
              tr(v-if="vehicle.engine_type")
                th Engine
                td {{ vehicle.engine_type }}
              tr(v-if="vehicle.transmission")
                th Transmission
                td {{ vehicle.transmission.name }}
              tr(v-if="vehicle.exterior_color")
                th Exterior Color
                td {{ vehicle.exterior_color.name }}
              tr(v-if="vehicle.interior_color")
                th Interior Color
                td {{ vehicle.interior_color.name }}
              tr(v-if="vehicle.title")
                th Title
                td {{ vehicle.title.name }}

        // Condition
        .column
          h3.subtitle.is-3 Condition
          table.table.is-fullwidth
            tbody
              tr(v-if="vehicle.status")
                th Status
                td
                  span.icon.has-text-success
                    font-awesome-icon(icon="signal")
                  |
                  | {{ vehicle.status.name }}
              tr(v-if="vehicle.drivable")
                th Drivable
                td
                  span.icon(:class="vehicle.drivable.id | signal")
                    font-awesome-icon(icon="signal")
                  |
                  | {{ vehicle.drivable.name }}
              tr(v-if="vehicle.interior_condition")
                th Interior
                td
                  span.icon(:class="vehicle.interior_condition.id | signal")
                    font-awesome-icon(icon="signal")
                  |
                  | {{ vehicle.interior_condition.name }}
              tr(v-if="vehicle.exterior_condition")
                th Interior
                td
                  span.icon(:class="vehicle.exterior_condition.id | signal")
                    font-awesome-icon(icon="signal")
                  |
                  | {{ vehicle.exterior_condition.name }}
              tr
                th Stains
                td
                  span.icon(:class="vehicle.stains | boolSignal")
                    font-awesome-icon(icon="signal")
                  |
                  | {{ vehicle.stains | yesNone }}
              tr
                th Tears
                td
                  span.icon(:class="vehicle.tears | boolSignal")
                    font-awesome-icon(icon="signal")
                  |
                  | {{ vehicle.tears | yesNone }}
              tr
                th Burns
                td
                  span.icon(:class="vehicle.burns | boolSignal")
                    font-awesome-icon(icon="signal")
                  |
                  | {{ vehicle.burns | yesNone }}

      // Description
      template(v-if="vehicle.description")
        h3.subtitle.is-3 Description
        p.description {{ vehicle.description }}

      // Features
      template(v-if="vehicleFeatures")
        h3.subtitle.is-3 Features
        .tags
          span.tag.is-info.is-light(v-for="feature in vehicleFeatures")
            strong.has-text-info-dark {{ feature.name }}

      // Disclosure
      template(v-if="disclosure")
        h3.subtitle.is-3 Disclosure Agreement
        p.description {{ disclosure.body }}

      // Warranty
      template(v-if="warranty")
        h3.subtitle.is-3 Warranty
        p.description {{ warranty.body }}

      // Drivable
      template(v-if="drivable")
        h3.subtitle.is-3 Drivability Details
        p.description {{ drivable.body }}

  transition(name="fade")
    div(v-show="displayQuestionModal")
      .modal.is-active
        .modal-background
        .modal-card
          header.modal-card-head
            p.modal-card-title Have a question?
            button.delete(@click="closeModal")
          section.modal-card-body
            w-form(ref="inquiryForm" :complete="sendInquiry")
              .columns.is-multiline
                .column.is-half
                  w-input.is-info(type="text" placeholder="First name" required v-model.trim="fName")
                .column.is-half
                  w-input.is-info(type="text" placeholder="Last name" required v-model.trim="lName")
                .column.is-full
                  w-input.is-info(type="email" placeholder="Email" required v-model.trim="email")
                .column.is-full
                  w-textarea.is-info(placeholder="Message" required v-model="message")
          footer.modal-card-foot
            template(v-if="inquirySent")
              button.button(@click="closeModal") Close
            template(v-if="!inquirySent")
              button.button(@click="closeModal") Cancel
              button.button.is-info(
                @click="submitInquiry"
                :disabled="inquiryError") Send

  transition(name="fade")
    div(v-show="displayApptModal")
      .modal.is-active
        .modal-background
        .modal-card
          header.modal-card-head
            p.modal-card-title Schedule an appointment
            button.delete(@click="closeModal")
          section.modal-card-body
            p Coming soon!
          footer.modal-card-foot
            button.button(@click="closeModal") Cancel
</template>

<script>
import apolloMixin from '@/mixins/apolloMixin'
import { Hooper, Slide, Navigation } from 'hooper'
import CurrentVehicle from '@/graphql/CurrentVehicle.gql'
import CreateGuest from '@/graphql/CreateGuest.gql'
import CreateInquiry from '@/graphql/CreateInquiry.gql'
import 'hooper/dist/hooper.css'

export default {
  mixins: [
    apolloMixin
  ],

  components: {
    Hooper,
    Navigation,
    Slide
  },

  mounted () {
    this.scrollTop(0.1)
  },

  data () {
    return {
      vehicle: {},
      fName: '',
      lName: '',
      email: '',
      message: '',
      guest: null,
      inquiry: null,
      inquirySent: false,
      inquiryError: false,
      transitions: ['swipe'],
      hooperMainSettings: {
        itemsToShow: 1,
        centerMode: true,
        playSpeed: 5000
      },
      hooperNavSettings: {
        itemsToShow: 5,
        centerMode: false,
        pagination: true,
        trimWhiteSpace: true
      },
      currencyOptions: {
        style: 'currency',
        currency: 'USD',
        maximumSignificantDigits: 3
      },
      displayQuestionModal: false,
      displayApptModal: false
    }
  },

  computed: {
    photos () {
      if (!this.vehicle || !this.vehicle.photos || !this.vehicle.photos.length) return null
      return this.vehicle.photos.map(photo => this.photoSrc(photo))
        .filter((v, i, a) => a.indexOf(v) === i)
        .map(url => { return { id: url.match(/\d+$/)[0], url: url } })
    },
    savings () {
      if (!this.vehicle || !this.vehicle.price || !this.vehicle.retail_price) return 0
      return this.vehicle.retail_price - this.vehicle.price
    },
    vehicleFeatures () {
      if (!this.vehicle || !this.vehicle.vehicles_features ||
        !this.vehicle.vehicles_features.length) return null
      return this.vehicle.vehicles_features
        .map(vehicleFeature => vehicleFeature.feature)
        .sort((a,b) => a.order > b.order ? 1 : -1)
    },
    disclosure () {
      if (!this.vehicle || !this.vehicle.vehicles_disclosures ||
        !this.vehicle.vehicles_disclosures.length) return null
      return this.vehicle.vehicles_disclosures[0].disclosure
    },
    warranty () {
      if (!this.vehicle || this.vehicle.warranty === {}) return null
      return this.vehicle.warranty
    },
    drivable () {
      if (!this.vehicle || this.vehicle.drivable === {}) return null
      return this.vehicle.drivable
    },
    guestData () {
      return {
        first_name: this.fName,
        last_name: this.lName,
        email: this.email,
        auth_id: 'wov|' + this.email
      }
    },
    inquiryData () {
      return {
        user_id: this.guest.id,
        vehicle_id: this.vehicle.id,
        body: this.message
      }
    },
    jsonLd () {
      const name = [this.vehicle.year, this.vehicle.make, this.vehicle.model].join(' ')
      return {
        '@context': 'https://schema.org/',
        '@type': 'Vehicle',
        'vehicleIdentificationNumber': this.vehicle.vin,
        'name': name,
        'productionDate': this.vehicle.year,
        'slogan': this.vehicle.subtitle,
        'manufacturer': this.vehicle.make,
        'model': this.vehicle.model,
        'image': [this.photos && this.photos[0]],
        'description': this.vehicle.description,
        'brand': {
          '@type': 'Thing',
          'name': this.vehicle.make
        },
        'offers': {
          '@type': 'Offer',
          'url': window.location.href,
          'priceCurrency': 'USD',
          'price': this.vehicle.price,
          'itemCondition': 'https://schema.org/UsedCondition',
          'availability': 'https://schema.org/' + (this.vehicle.sold ? 'SoldOut' : 'InStock'),
          'seller': {
            '@type': 'Organization',
            'name': 'Motor Car Export'
          }
        }
      }
    }
  },

  apollo: {
    vehicle: {
      query: CurrentVehicle,
      variables () {
        return {
          id: this.$route.params.id
        }
      },
      update: data => data.vehicles_by_pk
    }
  },

  filters: {
    title (vehicle) {
      return [vehicle.year, vehicle.make, vehicle.model].join(' ')
    },
    signal (value) {
      if (value < 3) {
        return 'has-text-success'
      } else if (value < 5) {
        return 'has-text-warning'
      } else {
        return 'has-text-error'
      }
    },
    boolSignal (value) {
      if (value) return 'has-text-warning'
      return 'has-text-success'
    },
    yesNone (value) {
      return value ? 'Yes' : 'None'
    },
    prettyPrice (value) {
      if (!value) return 'Call for price'
      const options = {
        style: 'currency',
        currency: 'USD',
        maximumSignificantDigits: 3
      }
      return Intl.NumberFormat('en-US', options).format(value)
    },
    prettyMiles (value) {
      if (!value) return null
      return Intl.NumberFormat('en-US').format(value)
    }
  },

  methods: {
    scrollTop (seconds) {
      var e = document.documentElement
      if (e.scrollTop === 0) {
        const t = e.scrollTop
        ++ e.scrollTop
        e = t + 1 === e.scrollTop -- ? e : document.body
      }
      this.scrollToC(e, e.scrollTop, 0, seconds * 1000)
    },
    scrollToC (element, from, to, duration) {
      if (duration <= 0) return
      if (typeof from === "object") from = from.offsetTop
      if (typeof to === "object") to = to.offsetTop

      this.scrollToX(element, from, to, 0, 1/duration, 20, this.easeOutCuaic)
    },
    scrollToX (element, xFrom, xTo, t01, speed, step, motion) {
      if (t01 < 0 || t01 > 1 || speed<= 0) {
        element.scrollTop = xTo
        return
      }
      element.scrollTop = xFrom - (xFrom - xTo) * motion(t01)
      t01 += speed * step

      setTimeout(() => {
        this.scrollToX(element, xFrom, xTo, t01, speed, step, motion)
      }, step)
    },
    easeOutCuaic (t) {
      t--
      return t*t*t+1
    },
    async submitInquiry () {
      this.$refs.inquiryForm.submitForm()
    },
    async sendInquiry () {
      await this.createGuest(this.createInquiry, this.$refs.inquiryForm)
    },
    async createGuest (callback, form) {
      await this.$apollo.mutate({
        mutation: CreateGuest,
        variables: this.guestData,
        loadingKey: 'savingCounter'
      }).then(result => {
        this.guest = result.data.insert_users.returning[0]
        callback()
      }).catch(error => {
        this.handleApolloError(form, error)
        this.inquiryError = true
      })
    },
    async createInquiry () {
      await this.$apollo.mutate({
        mutation: CreateInquiry,
        variables: this.inquiryData,
        loadingKey: 'savingCounter'
      }).then(() => {
        this.inquirySubmitted()
      }).catch(error => {
        this.handleApolloError(this.$refs.inquiryForm, error)
        this.inquiryError = true
      })
    },
    sendGaEvent (category, action) {
      this.$ga.event({
        eventCategory: category,
        eventAction: action,
        eventValue: this.vehicle.id
      })
    },
    askQuestion () {
      this.sendGaEvent('Ask a vehicle question', 'Click button')
      this.displayQuestionModal = true
      this.$refs.inquiryForm.resetForm()
    },
    inquirySubmitted () {
      this.$refs.inquiryForm.success('Your inquiry has been successfully submitted')
      this.inquirySent = true
    },
    closeModal () {
      this.displayQuestionModal = false
      this.displayApptModal = false
      this.inquirySent = false
      this.inquiryError = false
    },
    scheduleAppt () {
      this.sendGaEvent('Schedule an appointment', 'Click button')
      this.displayApptModal = true
    },
    photoSrc (photo) {
      const domain = process.env.VUE_APP_CDN
      if (process.env.NODE_ENV == 'development') {
        const id = (photo.id % 15) + 1108
        return domain + '/vehicles/845/photos/' + id
      } else {
        return domain + '/vehicles/' + this.vehicle.id + '/photos/' + photo.id
      }
    },
    updateSlideShow (slide) {
      this.$refs.slideshow.slideTo(slide)
    }
  }
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 500px) {
  .hooper.main {
    min-height: 230px;
  }
}
@media screen and (min-width: 501px) {
  .hooper.main {
    min-height: 300px;
  }
}
@media screen and (min-width: 601px) {
  .hooper.main {
    min-height: 400px;
  }
}
@media screen and (min-width: 769px) {
  .hooper.main {
    min-height: 550px;
  }
}
@media screen and (min-width: 950px) {
  .hooper.main {
    min-height: 650px;
  }
}
.hooper.nav {
  height: 200px;

  .hooper-slide img{
    width: 96%;
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }
}
.content-section {
  padding: 2rem 0;
}
.description {
  margin: 1rem 0;
}
.section {
  margin: 0.5rem 0;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.call-to-action {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
</style>
