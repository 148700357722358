export default {
  data () {
    return {
      apolloError: null
    }
  },

  methods: {
    handleApolloError (form, error) {
      this.apolloError = error
      console.log(error)
      let message
      if (error.message.match(/GraphQL error/)) {
        message = 'There was an error processing this. Sorry!'
      } else {
        // TODO: Detect and display more useful toasts for common failure scenarios
        // eg. jwt expired, duplicate record insertion attempt, etc.
        message = 'There was an error submitting this form. Sorry!'
      }
      form.broke(message)
    }
  }
}
