<template lang="pug">
div
  nav-bar(:links='topNavLinks')
  div.view-container(v-if="!authLoading")
    transition(:name='viewTransition')
      router-view(:key="pageKey")
  div.content-footer
</template>

<script>
import NavBar from "./components/NavBar";
import "core-js";

export default {
  name: "App",
  components: {
    NavBar
  },
  data() {
    return {
      viewTransition: "",
      topNavLinks: [
        { text: "Vehicles", path: "/" },
        { text: "Buyer Info", path: "/buyer-info" },
        { text: "FAQ", path: "/faq" },
        { text: "About", path: "/about" },
        { text: "Contact", path: "/contact" }
      ]
    };
  },
  computed: {
    pageKey() {
      return this.$route.path;
    },
    authLoading() {
      return this.$auth.loading;
    }
  },
  watch: {
    $route(to, from) {
      const fromIndex = this.topNavLinks.findIndex(
        link => link.path === from.path
      );
      const toIndex = this.topNavLinks.findIndex(link => link.path === to.path);
      const direction = this.determineDirection(fromIndex, toIndex);
      this.viewTransition = "slide-" + direction;
    }
  },
  methods: {
    determineDirection(from, to) {
      if (to < 0) return "right";
      if (from < 0) return "left";
      return from < to ? "right" : "left";
    }
  }
};
</script>

<style lang="scss">
@import "src/scss/_variables";
@import "src/scss/layout";
@import "~bulma";

.view-container {
  position: relative;
}
.content-footer {
  height: 70px;
}
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.3s ease;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.slide-left-enter,
.slide-left-leave-to,
.slide-right-enter,
.slide-right-leave-to {
  opacity: 0;
}
.slide-right-enter,
.slide-left-leave-to {
  transform: translateX(100%);
}
.slide-left-enter,
.slide-right-leave-to {
  transform: translateX(-100%);
}
</style>
