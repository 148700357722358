import Vue from "vue";
import Router from "vue-router";
import VehicleList from "./views/VehicleList.vue";
import Vehicle from "./views/VehicleShow.vue";
import CmsPage from "./views/CmsPage.vue";
import AdminHome from "./views/AdminHome.vue";
import VehicleManage from "./views/VehicleManage.vue";
import NotFound from "./views/NotFound.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "vehicles",
      component: VehicleList,
    },
    {
      path: "/vehicle/:id",
      name: "vehicle",
      component: Vehicle,
    },
    {
      path: "/admin",
      name: "admin",
      component: AdminHome,
      meta: { role: "admin" },
    },
    {
      path: "/manage-vehicles",
      name: "manageVehicles",
      component: VehicleManage,
      meta: { role: "admin" },
    },
    {
      path: "/:slug",
      name: "page",
      component: CmsPage,
    },
    {
      path: "*",
      name: "notFound",
      component: NotFound,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  // TODO: On first page load we need to wait for auth to be done loading.  Without this it thinks you are not logged in.
  const auth = Vue.prototype.$auth;
  const requiredRoute = to.matched.find(
    (record) => record.meta && record.meta.role
  );
  const requiredRole = requiredRoute && requiredRoute.meta.role;
  // await auth.getUser()
  if (requiredRole) {
    if (!auth.isAuthenticated) {
      // Login if they are not logged in
      auth.loginWithRedirect(to.path);
      next();
    } else if (requiredRole === "admin" && !auth.isAdmin) {
      // For admin only and they are not, take them to not found
      next({ name: "notFound" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
