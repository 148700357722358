<template lang="pug">
input(
  ref="input"
  :class="classes"
  @change="runValidations"
  @keyup="runValidations"
  v-bind:value="value"
  v-on:input="$emit('input', $event.target.value)")
</template>

<script>
export default {
  props: ['value'],

  data () {
    return {
      isValid: true
    }
  },

  computed: {
    classes () {
      const list = ['input']
      if (!this.isValid) {
        list.push('is-danger')
      }
      return list.join(' ')
    }
  },

  methods: {
    runValidations () {
      this.isValid = this.$refs.input.checkValidity()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
